// services.js
const state = {
  services: [
    { id: 'c1', service: 'Haar Knippen', price: '25' },
    { id: 'c2', service: 'Haar knippen / Degraderen', price: '27' },
    { id: 'c3', service: 'Baard', price: '20' },
    { id: 'c4', service: 'Haar en Baard', price: '40/45' },
    { id: 'c5', service: 'Haar wassen', price: '5' },
    { id: 'c6', service: 'Tondeuse (Zonder Afspraak)', price: '15' },
  ],
};

const getters = {
  allServices: (state) => state.services,
};

const actions = {
  fetchServices({ commit }) {
    // Simulating an API call, you can replace this with your actual data fetching logic
    const servicesData = state.services;
    commit('setServices', servicesData);
  },
};

const mutations = {
  setServices: (state, services) => {
    state.services = services;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
